.loginBox{
    width: 466px;
    height: 466px;
    position: fixed;
    left: 0;
    right: 0;
    top: -180px;
    bottom: 0;
    margin: auto;
}
.foo{
    color: rgb(102, 102, 102);
    text-align: center;
    font-size: 12px;
    position: fixed;
    bottom: 12px;
    text-align: center;
    left: 0;
    right: 0;
}