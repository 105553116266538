.patternBox{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 10;
    background: #fff;
}
.header{
    background-color: rgb(237, 237, 237);
    width: 100%;
}
.headerMenu{
    width: 800px;
    margin: 0 auto;
}
.headerMenu span{
    display: inline-block;
    font-size: 12px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 15px;
}
.bodyContent{
    width: 800px;
    margin: 0 auto;
    overflow: auto;
}
.bodyContentItem{
    width: 200px;
    float: left;
    margin-top: 16px;
}
.bodyContentItem .imgbox{
    width: 160px;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
}
.titleBox{
    color: rgb(126,126,126);
    font-size: 12px;
    text-indent: 19px;
    margin-top: 6px;
    float: left;
}