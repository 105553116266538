*{margin: 0;padding: 0;}
body {
  /* background-color: rgba(56, 57, 58, 1) !important; */
  font-size: 14px;
  overflow: hidden;
}

body,html{
  height: 100%;
  width: 100%;
}
img{
  display: block;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

#picker-wrapper {
  width: 144px;
  height: 150px;
  position: relative;
  float: left;
  overflow: hidden;
}
#slider-wrapper {
  width: 30px;
  height: 150px;
  position: relative;
  float: left;
}
#picker-indicator {
  width: 3px;
  height: 3px;
  position: absolute;
  border: 1px solid white;
}
#slider-indicator {
  width: 100%;
  height: 10px;
  position: absolute;
  border: 1px solid black;
}
