.left{
  width: 130px;
  top: 35px;
  position: fixed;
  border-right: solid 3px rgba(25, 25, 25, 1);
  transition: 0.3s;
  overflow-x: hidden;
}

.header{
  height: 35px;
  width: 100%;
  background-color: rgba(39, 39, 40, 1);
  border-bottom: solid 3px rgba(25, 25, 25, 1);
  position: fixed;
  left: 0;
  top: 0;
}
.menus{
  list-style: none;
  height: 100%;
  line-height: 35px;
}
.menus li{
  float: left;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 15px;
  cursor: pointer;
}
.menus li:hover{
  color: #FFFFFF;
}
.foldingLeftIcon{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 3px;
  right: 4px;
}
.foldingLeftIcon svg{
  -webkit-transform: rotate(-90.00deg);
  transform: rotate(-90.00deg);
  fill: transparent;
  stroke: rgba(116, 116, 116, 1);
  width: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.foldingLeftIcon svg:nth-child(2){
  left: 8px;
}
.right{
  position: fixed;
  width: 218px;
  border-left: solid 3px rgba(25, 25, 25, 1);
  right: 0;
  top: 35px;
  transition: 0.3s;
}
.splitIcon{
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1.00px;
  stroke-linecap: butt;
  height: 15px;
}
.flowItem{
  float: left;
  margin-left: 24px;
  margin-top: 20px;
  width: 75px;
}
.fashionBox{
  width: 96px;
  margin: 0 auto;
}
.fashionBoxItem{
  width: 96px;
  height: 96px;
  background-color: rgba(31, 31, 31, 1);
  margin-top: 15px;
  border: solid 1px #000;
  position: relative;
}
.closeIcon{
  width: 7px;
  position: absolute;
  right: 7px;
  fill: rgba(100, 100, 100, 1);
  top: -3px;
  cursor: pointer;
}
.fashionPic{
  position: absolute;
  width: 50px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  height: 70px;
}
.fashionName{
  color: rgb(192,192,192,168);
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  bottom: 5px;
  margin: auto;
  font-size: 12px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.fashionaddIcon{
  width: 17px;
  transform: rotateZ(45deg);
  fill: rgba(100, 100, 100, 1);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -10px;
  bottom: 0;
  height: 17px;
}
.middle{
  position: fixed;
  left: 130px;
  top: 35px;
  right: 218px;
  transition: 0.3s;
}
.middle3D,.middle2D{
  width: 50%;
  float: left;
  height: 100%;
  transition: 0.3s;
  overflow-x: hidden;
  position: relative;
}
.middle3D{
  border-right: solid 2px rgba(25, 25, 25, 1);
  background-image: linear-gradient(#B6B6BB, #F8F8FB);
}
.middle2D{
  border-left: solid 2px rgba(25, 25, 25, 1);
  background-image: linear-gradient(90deg,#E2E2E2 1px,transparent 0),linear-gradient(#E2E2E2 1px,transparent 0);
  background-size: 30px 30px;
  background-color: #E6E6E6;
}
.middle3DTitle{
  height: 27px;
  width: 100%;
  text-align: center;
  background-color: rgba(39, 39, 40, 1);
  border-bottom: solid 1px #000;
  color: rgba(255, 255, 255, 0.49);
  position: relative;
  overflow: hidden;
}
.middle3DMenu{
  height: 35px;
  line-height: 35px;
  width: 100%;
  text-align: center;
  background-color: rgba(56, 57, 58, 1);
  border-bottom: solid 1px #000;
}
.middle3DMenu button{
  display: inline-block;
  padding: 0 12px;
  color: rgba(255, 255, 255, 0.49);
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
}
.middle3DMenu button:focus{
  outline: none;
  border: none;
}
.middle3DContent{
  position: relative;
}

.middle3DContentObject{
  display: block; 
  position: absolute; 
  top: 0px; 
  left: 0px; 
  width: 100%; 
  height: 100%; 
  border: none; 
  padding: 0px; 
  margin: 0px; 
  opacity: 0; 
  z-index: -1000; 
  pointer-events: none;
}

.contextMenu button{
  background: none;
  outline: none;
  border: none;
  color: #FFFFFF;
  text-align: left;
  font-size: 12px;
  display: block;
  width: 100%;
  padding: 2px 0;
  text-indent: 5px;
}
.contextMenu button:focus{
  outline: none;
  border: none;
}
.contextMenu button:hover{
  background: rgb(68,189,252);
}
.rightMenu{
  align-items: center;
  justify-content: space-between;
  width: 110px;
  margin: 0 auto;
  height: 100%;
}
.canvas2D{
  display: block;
  margin: 0 auto;
  /* transform: scale(0.3) translate(-1075px, -1200px); */
}
.foot{
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  right: 0;
}
.cp_image_view{
  background-image: url(https://files.dizanz.com/2021-06-25/WechatIMG246.png);
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  z-index: 1;
  background-size: 247%;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

